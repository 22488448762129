<template>
<div class="legal-notice-page">
      <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
</head>
<h1>Mentions légales</h1>
<p>Arkadis Consulting srl (ITAA 52 350 795)</p>
<p>Représentée par son administrateur, Mr Karl Delobbe (ITAA 10 391 427)</p>
<p>N° d’entreprise 0772 482 363</p>
<p>Aucune information personnelle n’est collectée à votre insu et aucune information personnelle n’est cédée à un tiers.</p>
<p>Les données personnelles recueillies sur le site résultent de la communication volontaire d’informations via le formulaire présent sur ce site. Ces données sont exclusivement collectées pour un usage interne à l’Entreprise.</p>

      </div>

</template>

<script>

</script>

<style scoped>

.legal-notice-page{
      padding: 20px;
}

</style>